<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:13:51
 * @LastEditTime: 2022-09-26 10:14:02
 * @Description: 已发起工单-查看工单
-->
<template>
  <div>查看工单</div>
</template>

<script>
export default {};
</script>

<style>

</style>